@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.img-umbrella{
  background-image: url("./img/umbrella.png");
  height: 90px;
  width: 120px;
  margin: auto;
  background-repeat: no-repeat;
}
.img-tablette{
  background-image: url("./img/tablette.png");
  height: 200px;
  width: 200px;
  margin: auto;
  background-repeat: no-repeat;
}
.img-petit-logo-allianz{
  background-image: url("./img/petit_logo_allianz.png");
  height: 46px;
  width: 46px;
  margin: auto;
  background-repeat: no-repeat;
}
.img-petit-logo-rurale{
  background-image: url("./img/petit_logo_rurale.png");
  height: 46px;
  width: 46px;
  border-radius: 50%;
  margin: auto;
  background-repeat: no-repeat;
}
.wrapper-dashboard .push-tpl-1.paper.chargement{
  height: 376px;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  .icon{
    font-size: 250px;
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
}
.wrapper-dashboard .push-tpl-1.mon-agence .map.agence .icon{
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
.modification-mail p.accroche{
  font-size: 16px;
}